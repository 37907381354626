export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            textareaSelector: 'textarea[data-richtext=data-richtext]',

            onKeyupCallback: function (e) {

            },
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }
    }

    initOnRichTextAreas() {
        console.log('DEBUG - Initializing RichTextEditor...');

        let self = this;

        // Make sure we don't get any conflict when f.e. an AJAX loaded form
        // gets refreshed: don't get initialised multiple times on the same element.
        tinyMCE.remove();

        tinyMCE.init({
            skin    : false,
            branding: false,
            menu    : { },
            language: 'nl',
            selector: this.config.textareaSelector,
            plugins : 'legacyoutput table autoresize lists advlist',
            toolbar : 'undo redo | styleselect | removeformat | align | bold italic underline forecolor | bullist numlist | link | table',
            menubar : false,
            content_style: 'p { padding: 0; margin: 0; }',
            advlist_number_styles: 'default,lower-alpha,lower-roman,upper-alpha,upper-roman',

            // Make the RichTextEditor compatible when ajax requests are posting
            // the form data (typical use case is a confirmation dialog popping
            // up prior to the form submission); below snippet makes sure the
            // editor content is saved at any moment.
            setup: function (editor) {
                editor.on('change', function () {
                    editor.save();
                });

                editor.on('keyup', self.config.onKeyupCallback);
            }
        });

        console.log('DEBUG - Done initializing RichTextEditor');
    }
}
