import UnobtrusiveNotification from "./UnobtrusiveNotification";
import $ from "jquery";

export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            message: '',
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function () {};
        }

        this.$(this.$.proxy(function () {
            this.initEventListeners();
        }, this));
    }

    initEventListeners() {
        console.log('DEBUG GlobalAjaxFeedback - Initializing event listeners...');

        // By default this will fetch all AJAX call errors, because it listens to "global: true".
        // If you use "global: false" in your .ajax/.get/.post method this event will not be triggered.
        $(document).ajaxError($.proxy(this.onGlobalAjaxCallError, this));

        console.log('DEBUG GlobalAjaxFeedback - Done initializing event listeners');
    }

    onGlobalAjaxCallError(event, jqxhr) {
        // ajax request was aborted
        if ('status' in jqxhr && jqxhr.status === 0) {
            return;
        }

        let notification = new UnobtrusiveNotification({
            'debug': this.config.debug
        });

        notification.show(this.config.message);
    }
}
