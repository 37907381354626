export default class {
    constructor(el, config) {
        this.$ = $;
        this.$element = el;

        // default config:
        this.config = {
            debug: true,
            uploadFieldName: 'image',
            uploadFormName: '',
            url: '',
            selectors: {
                widget: '.file_upload_widget',
                uploadButton: '.file_upload_button',
                uploadingElement: '.file_upload_uploading',
                retrievingDataElement: '.file_upload_retrieving',
                linkElement: '.file_upload_link',
                removeElement: '.file_upload_remove'
            },
            templates: {
                preview: '<a href="%url%" download>%name%</a>',
                error: '<li><span class="form-error-icon badge badge-danger">Fout</span> <span class="form-error-message">%error%</span></li>',
                errorList: '<div class="invalid-feedback d-block"><ul class="list-unstyled mb-0">%errors%</ul></div>',
            },
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self.init();
        });
    }

    init() {
        console.log('DEBUG FileUpload - Initializing...');

        this.$widget = this.$element.parents(this.config.selectors.widget);

        this.$widget.find(this.config.selectors.uploadButton).parent().on('click', this.config.selectors.uploadButton, $.proxy(function(event) { this.openUploadDialog($(event.target));}, this));
        this.$widget.find(this.config.selectors.removeElement).on('click', $.proxy(this.removeFile, this));
        this.$widget.find(this.config.selectors.uploadingElement).hide();
        this.$widget.find(this.config.selectors.linkElement).show();
        this.$widget.find(this.config.selectors.removeElement).show();

        // @todo - add code for handling retrieving data for an already
        // set ID in the hidden field
        if ('' !== this.$widget.find('input[type=hidden]').val()) {
            this.retrieveFileDetails();
        }

        console.log('DEBUG FileUpload - Done initializing');
    }

    removeFile(event) {
        document.getElementById($(event.currentTarget).data('target')).value = '';

        this.$widget.find(this.config.selectors.linkElement).empty();

        this.$widget.find(this.config.selectors.removeElement).hide();
    }

    retrieveFileDetails() {
        console.log('DEBUG FileUpload - Retrieving file details...');
        this.showRetrievingData();
        $.ajax({
            type: 'GET',
            url: this.config.url,
            data: {id:this.$widget.find('input[type=hidden]').val()},
            dataType: 'json',
            complete: $.proxy(this.onUploadComplete, this),
        });
        console.log('DEBUG FileUpload - Done retrieving file details');
    }

    openUploadDialog($button) {
        console.log('DEBUG FileUpload - Opening file dialog...');
        var $form = $('<form method="POST" action="' + this.config.url + '"></form>');
        var $fileElement = $('<input type="file" name="' + this.config.uploadFormName + '[' + this.config.uploadFieldName + '][file]" />');
        $form.append($fileElement);
        $fileElement.click();
        $fileElement.change($.proxy(function (event) {
            this.showUploading();
            this.$widget.find('input[type=hidden]').val('');

            var formData = new FormData($form[0]);

            $.ajax({
                type: 'POST',
                url: this.config.url,
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: false,
                cache: false,

                complete: $.proxy(this.onUploadComplete, this),
            });
        }, this));
        console.log('DEBUG FileUpload - Done opening file dialog');
    }

    showUploading() {
        console.log('DEBUG FileUpload - Showing uploading...');
        this.$widget.find(this.config.selectors.uploadingElement).show();
        this.$widget.find(this.config.selectors.retrievingDataElement).hide();
        this.$widget.find(this.config.selectors.linkElement).hide();
        this.$widget.find(this.config.selectors.removeElement).hide();
        this.$widget.find('.invalid-feedback').remove();
        console.log('DEBUG FileUpload - Done showing uploading');
    }

    showRetrievingData() {
        console.log('DEBUG FileUpload - Showing uploading...');
        this.$widget.find(this.config.selectors.uploadingElement).hide();
        this.$widget.find(this.config.selectors.retrievingDataElement).show();
        this.$widget.find(this.config.selectors.linkElement).hide();
        this.$widget.find(this.config.selectors.removeElement).hide();
        this.$widget.find('.invalid-feedback').remove();
        console.log('DEBUG FileUpload - Done showing uploading');
    }

    showTemplate(name, data) {
        console.log('DEBUG FileUpload - Showing template...');
        this.$widget.find(this.config.selectors.uploadingElement).hide();
        this.$widget.find(this.config.selectors.retrievingDataElement).hide();
        var content = this.config.templates[name];

        $.each(data, $.proxy(function (key, value) {
            content = this.replaceAll(content, '%' + key + '%', value);
        }, this));

        this.$widget.find(this.config.selectors.linkElement).html(content).show();
        this.$widget.find(this.config.selectors.removeElement).show();
        console.log('DEBUG FileUpload - Done showing template');
    }

    showErrors(errors) {
        console.log('DEBUG FileUpload - Showing errors...');

        var gatheredErrors = [];
        $.each(errors, $.proxy(function (key, value) {
            gatheredErrors.push(this.replaceAll(
                this.config.templates['error'],
                '%error%',
                value
            ));
        }, this));

        var content = this.config.templates['errorList'];
        content = this.replaceAll(content, '%errors%', gatheredErrors.join(''));

        this.$widget.find(this.config.selectors.uploadingElement).hide();
        this.$widget.find(this.config.selectors.retrievingDataElement).hide();
        this.$widget.find(this.config.selectors.linkElement).hide();
        this.$widget.append(content);

        console.log('DEBUG FileUpload - Done showing errors');
    }

    replaceAll(target, search, replacement) {
        return target.split(search).join(replacement);
    };

    onUploadComplete(event) {
        console.log('DEBUG FileUpload - Handling upload complete...');
        var response = $.parseJSON(event.responseText);

        if (response.success) {
            this.showTemplate('preview', response.payload);
            this.$widget.find('input[type=hidden]').val(response.payload.id);
        } else {
            this.showErrors(response.payload.errors);
        }
        console.log('DEBUG FileUpload - Done handling upload complete');
    }
}
