export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            selectors: {
                container: '.parameters',
                row: '.row',
                absoluteOnlyDeclaration: '[name*=absolute_only]',
                valueElements: '[name*=defaultValue]',
                absoluteElement: '[name$=%type%\\]]',
                relativeElement: '[name$=%type%_relative\\]]',
                typeElement: '[name*="[type]"]',
                declarationElement: '[name*="[declaration]"]',
                valueElementsContainer: '.parameter_default_value_widget',
                toggleDeclaration: 'a:has(.fa-bolt)',
            },
            callbacks: {
                afterSwitch: function () {},
            },
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self.init();
        });
    }

    init() {
        console.log('DEBUG ParameterAbsoluteOnlyDeclarationSwitcher - Initializing...');

        // Set initial state
        $(this.config.selectors.container).find(this.config.selectors.row).find(this.config.selectors.absoluteOnlyDeclaration).each($.proxy(function(index, item) {
            this.switchDeclaration($(item));
        }, this));

        // Set event listeners
        $(this.config.selectors.container).on('change', this.config.selectors.absoluteOnlyDeclaration, $.proxy(function(event) { this.switchDeclaration($(event.target));}, this));

        console.log('DEBUG ParameterAbsoluteOnlyDeclarationSwitcher - Done initializing');
    }

    switchDeclaration($element) {
        console.log('DEBUG ParameterAbsoluteOnlyDeclarationSwitcher - Handling change of switchField...');

        let $row = $element.parents(this.config.selectors.row).first();

        let $typeElement = $row.find(this.config.selectors.typeElement);

        let $relativeElement    = $row.find(this.config.selectors.relativeElement.replace(new RegExp('%type%', 'g'), $typeElement.val()));
        let $absoluteElement    = $row.find(this.config.selectors.absoluteElement.replace(new RegExp('%type%', 'g'), $typeElement.val()));
        let $declarationElement = $row.find(this.config.selectors.declarationElement);

        if (($element.attr('type') === 'checkbox' && $element.is(':checked'))
            || ($element.attr('type') === 'hidden' && $element.val() === '1')) {
            $relativeElement.parents(this.config.selectors.valueElementsContainer).hide();
            $absoluteElement.parents(this.config.selectors.valueElementsContainer).show();
            $declarationElement.prop('checked', false);
            $row.find(this.config.selectors.toggleDeclaration).addClass($element.attr('type') === 'hidden' ? 'invisible' : 'disabled');
        } else {
            if ($relativeElement.length) {
                if ($relativeElement.data('ajax--url')
                    || ! $.isEmptyObject($relativeElement.data('data'))
                ) {
                    $row.find(this.config.selectors.toggleDeclaration).removeClass('disabled invisible');
                }
            }
        }

        if ($absoluteElement.hasClass('select2entity')) {
            $absoluteElement.select2entity();
        }

        if (typeof this.config.callbacks.afterSwitch == 'function') {
            this.config.callbacks.afterSwitch($row);
        }

        console.log('DEBUG ParameterAbsoluteOnlyDeclarationSwitcher - Done handling change of switchField');
    }
}
