export default class {
    constructor(config) {
        this.$ = $;
        this._usageResponse = {};

        // default config:
        this.config = {
            debug: true,
            selectors: {
                container: '.parameters',
                row: '.row',
                declaration: '[name*=declaration]',
                valueElements: '[name*=defaultValue]',
                relativeElement: '[name*=%type%_relative]',
                typeElement: '[name*="[type]"]',
                valueElementsContainer: '.parameter_default_value_widget',
                toggleDeclaration: 'a:has(.fa-bolt)'
            },
            callbacks: {
                afterSwitch: function () {},
            },
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self.init();
        });
    }

    init() {
        console.log('DEBUG ParameterDeclarationSwitcher - Initializing...');
        let self = this;

        // Set initial state
        $(this.config.selectors.container).find(this.config.selectors.row).find(this.config.selectors.declaration).each($.proxy(function(index, item) {
            if ($(item).is(':checked')) {
                $(item).closest('div.row').find(this.config.selectors.toggleDeclaration).addClass('bg-warning');
            }

            self.switchDeclaration($(item));
        }, this));

        $(this.config.selectors.container).on('change', this.config.selectors.declaration, $.proxy(function(event) {
            self.switchDeclaration($(event.target));
        }, this));

        this.addDeclarationToggle();

        console.log('DEBUG ParameterDeclarationSwitcher - Done initializing');
    }

    switchDeclaration($element) {
        console.log('DEBUG ParameterDeclarationSwitcher - Handling change of declaration...');

        let $row = $element.parents(this.config.selectors.row).first();

        $row.find(this.config.selectors.valueElementsContainer).hide();

        let $typeElement = $row.find(this.config.selectors.typeElement);

        if ($element.attr('type') === 'checkbox' && $element.is(':checked')) {
            let $relativeElement = $row.find(this.config.selectors.relativeElement.replace(new RegExp('%type%', 'g'), $typeElement.val()));
            $relativeElement.parents(this.config.selectors.valueElementsContainer).show();

            if ($relativeElement.hasClass('select2entity')) {
                $relativeElement.select2entity();
            }
        }

        if (typeof this.config.callbacks.afterSwitch == 'function') {
            this.config.callbacks.afterSwitch($row);
        }

        $row.find(this.config.selectors.toggleDeclaration).toggleClass('bg-warning', $element.is(':checked'));

        console.log('DEBUG ParameterDeclarationSwitcher - Done handling change of declaration');
    }

    addDeclarationToggle() {
        console.log('DEBUG ParameterDefinitions - Start adding declaration toggle...');

        let self = this;

        $(this.config.selectors.container).off('click', this.config.selectors.toggleDeclaration);
        $(this.config.selectors.container).on('click', this.config.selectors.toggleDeclaration, function(event) {
            event.preventDefault();
            let $checkbox = $(event.target).closest('div.row').find('input[type="checkbox"][name*=declaration]');
            $checkbox.prop('checked', ! $checkbox.is(':checked'));
            self.switchDeclaration($checkbox);
        });

        console.log('DEBUG ParameterDefinitions - Added declaration toggle...');
    }
}
