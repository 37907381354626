import $ from 'jquery';
import 'bootstrap';
import Popper from 'popper.js';
import CRUD from "./_ui/CRUD";
import ContactUserDecisionOnDuplicate from "./ContactUserDecisionOnDuplicate";
import DataTable from "./_ui/DataTable";
import EditInPlace from "./_ui/EditInPlace";
import TimeRegistration from "./_form/TimeRegistration";
import Notifications from './messaging/Notifications';
import ShowModal from "./_form/ShowModal";
import 'jquery-ui/ui/widgets/sortable';
import 'select2';
import 'admin-lte/dist/js/adminlte.min';
import 'admin-lte/plugins/datatables/jquery.dataTables.min';
import 'admin-lte/plugins/datatables/dataTables.bootstrap4';
import 'datatables.net-fixedheader/js/dataTables.fixedHeader.min';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import jsZip from 'jszip';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import 'datatables.net-buttons/js/buttons.print.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-rowgroup-bs4/js/rowGroup.bootstrap4.min';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.nl-BE.min.js';
import moment from 'moment';
import GlobalAjaxFeedback from './_ui/Notification/GlobalAjaxFeedback';
import autosize from 'autosize/dist/autosize.min.js';
import Tribute from "tributejs";
import tinyMCE from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/legacyoutput';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce-i18n/langs5/nl.js';
import RichTextEditorProvider from "./_ui/RichTextEditorProvider";
import FileUpload from "./_form/FileUpload";

window.$ = $;
window.popper = Popper;
window.CRUD = CRUD;
window.ContactUserDecisionOnDuplicate = ContactUserDecisionOnDuplicate;
window.DataTable = DataTable;
window.EditInPlace = EditInPlace;
window.TimeRegistration = TimeRegistration;
window.Notifications = Notifications;
window.ShowModal = ShowModal;
window.moment = moment;
window.GlobalAjaxFeedback = GlobalAjaxFeedback;
window.JSZip = jsZip;
window.Tribute = Tribute;
window.tinyMCE = tinyMCE;
window.RichTextEditorProvider = RichTextEditorProvider;
window.FileUpload = FileUpload;

window.initDatepicker = function () {
    $('.datepicker').each(function(i, element) {
        let selectedDate = $(element).data('selected-date') ? moment($(element).data('selected-date'), 'DD/MM/YYYY') : '';

        let datePicker = $(element)
            .datepicker({
                format: 'dd/mm/yyyy',
                language: 'nl-BE',
                todayHighlight: true,
                autoclose: true,
                startDate: $(element).data('lower-limit-date'),
                endDate: $(element).data('upper-limit-date') ? $(element).data('upper-limit-date') : false
            });

        if ($(element).data('selected-date')) {
            datePicker.datepicker('setDate', selectedDate.format('DD/MM/YYYY'));
        }
    })
}

window.initOnLoad = function () {
    $('[data-toggle="popover"]').popover();
    initDatepicker();
    initRichTextEditors();
};

$(function () {
    initOnLoad();

    // on first focus (bubbles up to document), open the menu
    $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
        $(this).closest('.select2-container').siblings('select:enabled').select2('open');
    });

    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function (e) {
        $(e.target).data('select2').$selection.one('focus focusin', function (e) {
            e.stopPropagation();
        });
    });

    // select with tab
    $('.select2entity').on('select2:close', function(evt) {
        let context = $(evt.target);

        $(document).on('keydown.select2', function (e) {
            // When the keycode is not 9 (tab)
            if (e.which !== 9) {
                return;
            }

            let highlighted = context
                .data('select2')
                .$dropdown
                .find('.select2-results__option--highlighted');

            if (highlighted) {
                let id = highlighted.data('data').id;
                context.val(id).trigger('change');
            }
        });

        // unbind the event again to avoid binding multiple times
        setTimeout(function () {
            $(document).off('keydown.select2');
        }, 1);
    });

    localStorage.removeItem('tr_last_selected');

    autosize(document.querySelectorAll('textarea'));

    $(document).on('shown.bs.modal', '.modal', function () {
        window.autosizeTextareas();
    });

    // open tab based on hash (...#documents f.e.)
    const hash = window.location.hash;
    hash && $('ul.nav a[href="' + hash + '"]').tab('show');
});

window.autosizeTextareas = function () {
    autosize(document.querySelectorAll('textarea'));
    autosize.update(document.querySelectorAll('textarea'));
};

window.initRichTextEditors = function () {
    let richTextEditorProvider = new RichTextEditorProvider();
    richTextEditorProvider.initOnRichTextAreas();
}


