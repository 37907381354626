import './SymfonyCollection';

export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            collections: {
            },
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self.initEventListeners();
        });
    }

    initEventListeners() {
        console.log('DEBUG Collections - Initializing event listeners...');

        $.each(this.config.collections, function (selector, config) {
            $(selector).collection(config);
        });

        console.log('DEBUG Collections - Done initializing event listeners');
    }
}
