export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            selectors: {
                editableElements: '[contenteditable="true"]',
            }
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self.initEventListeners();
        });
    }

    initEventListeners() {
        console.log('DEBUG EditInPlace - Initializing event listeners...');

        $(document).on('blur', this.config.selectors.editableElements, $.proxy(this.onAfterEdit, this));

        console.log('DEBUG EditInPlace - Done initializing event listeners');
    }

    onAfterEdit(event) {
        console.log('DEBUG EditInPlace - Saving changes...');

        const $element = this.$(event.target);

        let data = {};

        data[$element.attr('data-post-var')] = $element.get(0).innerText.trim();

        if (typeof $element.attr('data-post-additional-data') !== 'undefined') {
            this.$.extend(true, data, JSON.parse($element.attr('data-post-additional-data')));
        }

        this.$.post(
            Routing.generate(
                $element.attr('data-route'),
                JSON.parse($element.attr('data-route-params'))
            ),
            data,
            $.proxy(this.afterSave, this, $element)
        );

        console.log('DEBUG EditInPlace - Done saving changes');
    }

    afterSave($element) {
        console.log('DEBUG EditInPlace - After save callback...');
        console.log('DEBUG EditInPlace - Finished after save callback');
    }
}
