import "inputmask/dist/jquery.inputmask.bundle";
import "inputmask/dist/inputmask/phone-codes/phone";
import "inputmask/dist/inputmask/inputmask.date.extensions";

export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            selectors: {
                kindField: '[name*=kind]',
                fieldContainer: '.form-group',
            },
            i18n: {
                'Add': 'Add'
            }
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self._buildRequiredFields();
            self.initInputmasks();
            self.toggleFields();
            self.addListeners();
        });
    }

    initInputmasks() {
        console.log('DEBUG CRUD - initializing inputmasks...');
        Inputmask().mask(document.querySelectorAll("input"));
        $(document).on('click', '.collection-action', function(e) {
            console.log('DEBUG CRUD - re-initializing inputmasks...');
            Inputmask().mask($('input'));
        });
        console.log('DEBUG CRUD - done initializing inputmasks');
    }

    toggleFields() {
        console.log('DEBUG CRUD - toggling input fields...');
        var self = this;
        var $kindField = $(this.config.selectors.kindField);
        var currentKind = $kindField.val();
        var $contactForm = $kindField.parents('form');

        // hide all kind-specific fields
        $contactForm.find('[data-kind]').closest(this.config.selectors.fieldContainer).hide();

        // make everything non-required by default
        $contactForm.find('[required]').each(function (index, el) {
            $(el).attr('required', false);
        });

        // show kind-specific fields
        if ('' !== currentKind) {
            var $kindFields = $contactForm.find('[data-kind*=' + currentKind + ']');
            $kindFields.closest(this.config.selectors.fieldContainer).show();

            // @todo  toggle required fields
            $kindFields.each(function(index, el) {
                var $el = $(el);
                if (self.isRequiredField($el.attr('id'), currentKind)) {
                    $el.attr('required', true);
                    $el.siblings('label, legend').addClass('required');
                } else {
                    $el.attr('required', false);
                    $el.siblings('label, legend').removeClass('required');
                }
            });
        }
        console.log('DEBUG CRUD - done toggling input fields');
    }

    addListeners() {
        console.log('DEBUG CRUD - adding listeners...');
        var self = this;
        var $kindField = $(this.config.selectors.kindField);
        $(document).on('change', '#' + $kindField.attr('id'), function(e) {
            self.toggleFields();
            Inputmask().mask($('input').filter(function() { return !this.value; }));
        });

        console.log('DEBUG CRUD - done adding listeners');
    }

    _buildRequiredFields() {
        console.log('DEBUG CRUD - building required fields...');
        if (typeof this._requiredFields !== 'undefined') {
            return
        }

        this._requiredFields = {};

        var $kindField = $(this.config.selectors.kindField);
        var $contactForm = $kindField.parents('form');
        var self = this;
        $contactForm.find('[data-required]').each(function (index, el) {
            var $el = $(el);
            self._requiredFields[$el.attr('id')] = $el.data('required');
        });
        console.log('DEBUG CRUD - done building required fields');
    }

    isRequiredField(field, kind) {
        if (typeof this._requiredFields[field] === 'undefined') {
            return false;
        }

        if (-1 !== this._requiredFields[field].indexOf(kind)) {
            return true;
        }

        return false;
    }
}
