export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            constants: {
                allowedDuplicate: '',
                forbiddenDuplicate: '',
                userDecidesToCreate: ''
            },
            selectors: {
                form: 'form[name="create"]',
                userDecisionFormField: '#create_onDuplicateUserDecision',
                onDuplicatePopup: '#on_duplicate_popup',
                createAction: '#on_duplicate_create_anyway',
                decisionsWhenAllowed: '.allowed-option',
                duplicateList: '#duplicates-list'
            },
            i18n: {
                existingContact: 'reeds bestaande contact',
                existingName: 'reeds bestaande naam',
                existingNationalNumber: 'reeds bestaand rijksregisternummer',
                existingCompanyNumber: 'reeds bestaand ondernemingsnummer'
            }
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self.initOnDuplicateShowPopup();
            self.initEventListeners();
        });
    }

    initOnDuplicateShowPopup() {
        console.log('DEBUG - Initializing on duplicate modal...');

        if ($(this.config.selectors.userDecisionFormField).data('type') === this.config.constants.allowedDuplicate) {
            this.onAllowedDuplicate();
        }

        if ($(this.config.selectors.userDecisionFormField).data('type') === this.config.constants.forbiddenDuplicate) {
            this.onForbiddenDuplicate();
        }

        console.log('DEBUG - Done initializing on duplicate modal');
    }

    initEventListeners() {
        console.log('DEBUG - Initializing event listeners on duplicate...');

        $(this.config.selectors.createAction).on('click', $.proxy(this.onCreateTheDuplicateAnyway, this));

        console.log('DEBUG - Done initializing event listeners on duplicate');
    }

    onCreateTheDuplicateAnyway(e) {
        $(this.config.selectors.userDecisionFormField).val(this.config.constants.userDecidesToCreate);
        $(this.config.selectors.form).submit();
    }

    onAllowedDuplicate() {
        $(this.config.selectors.onDuplicatePopup).find(this.config.selectors.decisionsWhenAllowed).show();
        $(this.config.selectors.onDuplicatePopup).modal('show');

        this.fillDuplicatesList($(this.config.selectors.userDecisionFormField).data('list'), this.config.constants.allowedDuplicate);
    }

    onForbiddenDuplicate() {
        $(this.config.selectors.onDuplicatePopup).find(this.config.selectors.decisionsWhenAllowed).hide();
        $(this.config.selectors.onDuplicatePopup).modal('show');

        this.fillDuplicatesList($(this.config.selectors.userDecisionFormField).data('list'), this.config.constants.forbiddenDuplicate);
    }

    fillDuplicatesList(duplicateContacts, duplicateType) {
        if (duplicateContacts === '') {
            return;
        }

        $(duplicateContacts).each($.proxy(function(index, contact) {
            var duplicateLink = Routing.generate('contactlist.details', {id: contact['contact_id']});
            var duplicateAttribute = this.getDuplicateAttribute(contact, duplicateType);
            var name = contact[this.config.constants.allowedDuplicate];
            var number = contact[this.config.constants.forbiddenDuplicate];
            number = (null === number ? '':number);

            $(this.config.selectors.onDuplicatePopup).find(this.config.selectors.duplicateList).append(
                '<a href="'+duplicateLink+'">'
                +'<li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">'
                + name + ': '
                + number
                + '<br>' + duplicateAttribute
                + '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i></li></a>'
            );
        }, this));
    }

    appendTitle(subtitleId, text){
        $(this.config.selectors.onDuplicatePopup).find(subtitleId).append(
            text
        );
    }

    getDuplicateAttribute(contact, duplicateType){
        var duplicateAttribute = this.config.i18n.existingContact;
        if(this.config.constants.allowedDuplicate === duplicateType){
            duplicateAttribute = this.config.i18n.existingName;
        } else if(this.config.constants.forbiddenDuplicate === duplicateType) {
            if (contact['type'] === 'company') {
                duplicateAttribute = this.config.i18n.existingCompanyNumber;
            }
            else if (contact['type'] === 'person') {
                duplicateAttribute = this.config.i18n.existingNationalNumber;
            }
        }

        return duplicateAttribute;
    }
}
