import $ from "jquery";

export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            selectors: {
                modal: null,
                container: null,
                form: null,
                buttonShow: null
            }
        };

        this.url  = null;
        this.init = true;

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function () {
            };
        }

        var self = this;
        this.$(function () {
            self.initEventListeners();
        });
    }

    initEventListeners() {
        console.log('DEBUG ShowModal - Initializing event listeners...');

        $(document).on('click', this.config.selectors.buttonShow, $.proxy(this.initOnClick, this));
        $(this.config.selectors.modal).on('hidden.bs.modal', $.proxy(this.reset, this));

        console.log('DEBUG ShowModal - Done initializing event listeners');
    }

    initOnClick(event) {
        if ($(event.currentTarget).data('url') === undefined) {
            return;
        }

        this.url = $(event.currentTarget).data('url');
        this.onEventTriggered(event);
    }

    onEventTriggered(event) {
        let form = $(this.config.selectors.form).serialize();

        $.get(
            this.url,
            form,
            $.proxy(function (response) {
                this.replaceForm(response);
            }, this)
        );
    }

    replaceForm(response) {
        $(this.config.selectors.container).html(response);
        $(this.config.selectors.modal).modal('show');

        $(this.config.selectors.modal).find('.select2entity[data-autostart="true"]:not([multiple])').select2entity({
            dropdownParent: $(this.config.selectors.container)
        });

        $(this.config.selectors.modal).find('.select2entity[data-autostart="true"][multiple="multiple"]').select2_sortable({
            dropdownParent: $(this.config.selectors.container)
        });
    }
}
