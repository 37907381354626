export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            selectors: {
                log: '#top-log'
            },
            displayTime: 2500
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }
    }

    show(message) {
        let $log = this.$(this.config.selectors.log);
        $log.html(message);
        window.setTimeout(this.$.proxy(this.clearLog, this), this.config.displayTime);
    }

    clearLog() {
        let $log = this.$(this.config.selectors.log);
        $log.find('*').fadeOut('slow', function() {
            $(this).remove();
        })
    }
}
