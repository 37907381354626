export default class {
    constructor(config) {
        this.$ = $;
        this.table = null;

        // default config:
        this.config = {
            debug: true,
            table: 'table',
            options: {
                "fixedHeader": true,
                "dom": "<'row'<'col-sm-12 col-md-6'lf><'col-sm-12 col-md-6'B>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                "buttons": ['colvis'],
                "disablePageLengthAllOption": false,
                "showTotalNumberOfRecords": false,
                "language": {
                    "lengthMenu": Translator.trans('Toon')+
                        ' <select class="form-control form-control-sm">'+
                        '<option value="-1">alle</option>'+
                        '<option value="10">10</option>'+
                        '<option value="25">25</option>'+
                        '<option value="50">50</option>'+
                        '<option value="75">75</option>'+
                        '<option value="100">100</option>'+
                        '<option value="250">250</option>'+
                        '</select> '+
                        Translator.trans('items per pagina'),
                    "zeroRecords": Translator.trans('Geen resultaten gevonden'),
                    "info": Translator.trans('Pagina _PAGE_ van _PAGES_'),
                    "infoFiltered": Translator.trans('- _TOTAL_ gevonden na filtering'),
                    "infoEmpty": "",
                    "search": Translator.trans('Zoeken'),
                    "processing": Translator.trans('Aan het laden'),
                    "paginate": {
                        "next": Translator.trans('Volgende'),
                        "previous": Translator.trans('Vorige'),
                        "first": Translator.trans('Eerste'),
                        "last": Translator.trans('Laatste')
                    },
                    "buttons": {
                        "colvis": Translator.trans('Zichtbare kolommen')
                    },
                    "order": [[0, 'asc']]
                }
            },
            events: {
            }
        };

        this.$.extend(true, this.config, config);

        // We must overwrite the lengthMenu options,
        // because we do not want to see the "all" option cause the maximum in "ElasticSearch" is 10000
        if (this.config.options.disablePageLengthAllOption) {
            this.config.options.language.lengthMenu = Translator.trans('Toon')+
                ' <select class="form-control form-control-sm">'+
                '<option value="10">10</option>'+
                '<option value="25">25</option>'+
                '<option value="50">50</option>'+
                '<option value="75">75</option>'+
                '<option value="100">100</option>'+
                '<option value="250">250</option>'+
                '</select> '+
                Translator.trans('items per pagina');
        }

        // Append total number of records to the "info" string.
        if (true === this.config.options.showTotalNumberOfRecords) {
            this.config.options.language.info = Translator.trans(
                'Pagina _PAGE_ van _PAGES_ (_MAX_ resultaten gevonden)'
            );
        }

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        this.init();
    }

    init() {
        console.log('DEBUG DataTable - Initializing datatable...');

        this.table = $(this.config.table).DataTable(
            this.config.options
        );

        this.$.each(this.config.events, this.$.proxy(function(eventName, handler) {
            this.table.on(eventName, handler);
        }, this));

        console.log('DEBUG DataTable - Done initializing datatable');
    }

    fetchData(url, callback) {
        console.log('DEBUG DataTable - Refreshing datatable...');

        if (typeof callback == 'undefined') {
            callback = function(data) {};
        }

        this.table.ajax.url(url).load(callback);
        this.$(this.config.table).trigger('argus.datatable.reload');

        console.log('DEBUG DataTable - Done refreshing datatable');
    }
}
