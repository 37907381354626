export default class {
    constructor(config) {
        this.$ = $;
        this._usageResponse = {};
        this.initialized = false;

        // default config:
        this.config = {
            debug: true,
            selectors: {
                container: '.parameters',
                row: '.row',
                switchField: '[name*="[type]"]',
                fieldToSwitchContainer: '.parameter_default_value_widget',
                fieldToSwitch: '[name*="[defaultValue_%type%]"]',
                relativeElement: '[name*=%type%_relative]',
            },
            callbacks: {
                afterSwitch: function () {},
                allowToSwitch: function ($row) {
                    return true;
                }
            },
        };

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function() {};
        }

        var self = this;
        this.$(function() {
            self.init();
        });
    }

    init() {
        console.log('DEBUG ParameterTypeSwitcher - Initializing initial state...');

        // Set initial state
        $(this.config.selectors.container).find(this.config.selectors.row).find(this.config.selectors.switchField).each($.proxy(function(index, item) {
            console.log('DEBUG ParameterTypeSwitcher - Setting initial state...', $(item));
            this.switchVisibleField($(item));
            console.log('DEBUG ParameterTypeSwitcher - Done setting initial state', $(item));
        }, this));

        // Set event listeners
        $(this.config.selectors.container).off('change', this.config.selectors.switchField);
        $(this.config.selectors.container).on('change', this.config.selectors.switchField, $.proxy(function(event) {
            console.log('DEBUG ParameterTypeSwitcher - Switching visible field...');
            this.switchVisibleField($(event.target));
            console.log('DEBUG ParameterTypeSwitcher - Done switching visible field');
        }, this));

        this.initialized = true;

        console.log('DEBUG ParameterTypeSwitcher - Done initializing initial state');
    }

    switchVisibleField($selectItem) {
        console.log('DEBUG ParameterTypeSwitcher - Handling change of switchField...');

        let $row = $selectItem.parents(this.config.selectors.row).first();

        let allowToSwitch = true;
        if (typeof this.config.callbacks.allowToSwitch == 'function') {
            allowToSwitch = this.config.callbacks.allowToSwitch($row);
        }

        if (! allowToSwitch) {
            console.log('DEBUG ParameterTypeSwitcher - Not allowed to switch');
            return;
        }

        let $typeElement = $row.find(this.config.selectors.switchField);
        let $relativeElement = $row.find(
            this.config.selectors.relativeElement.replace(
                new RegExp('%type%', 'g'),
                $typeElement.val()
            )
        );

        $row.find('a:has(.fa-bolt)').removeClass('invisible');
        // hide bolt if no context alias
        if ($relativeElement.length) {
            if (! $relativeElement.data('ajax--url')
                && $.isEmptyObject($relativeElement.data('data'))
            ) {
                $row.find('a:has(.fa-bolt)').addClass('invisible');
            }
        }

        // toggle fields
        $row.find(this.config.selectors.fieldToSwitchContainer).hide();
        $row.find(this.config.selectors.fieldToSwitch.replace(new RegExp('%type%', 'g'), $selectItem.val())).parents(this.config.selectors.fieldToSwitchContainer).show();

        // re-init select2entity, datepicker and rich_text_editor(s)
        $row.find(this.config.selectors.fieldToSwitch.replace(new RegExp('%type%', 'g'), $selectItem.val())).each(function(index, item) {
            if ($(item).hasClass('select2entity')) {
                $(item).select2entity();
            }

            if ($(item).hasClass('datepicker')) {
                $(item).datepicker({
                    format: 'dd/mm/yyyy',
                    language: 'nl-BE',
                    todayHighlight: true,
                    autoclose: true
                });

                Inputmask().mask($(item));
            }

            if (item.hasAttribute('data-richtext')) {
                var rte = new RichTextEditorProvider();
                rte.initOnRichTextAreas();
            }
        });

        // set correct required
        if (true === this.initialized
            && typeof this.config.callbacks.afterSwitch == 'function') {
            this.config.callbacks.afterSwitch($row);
        }

        console.log('DEBUG ParameterTypeSwitcher - Done handling change of switchField');
    }
}
