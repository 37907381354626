import $ from "jquery";

export default class {
    constructor(config) {
        this.$ = $;

        this.config = {
            debug: true,
            selectors: {
                modal: '#notifications',
                container: '#notifications-entries',
                containerUnreadNumber: '#total-unread-notifications',
                buttonShow: '#show-notification-modal',
                buttonMarkAsArchived: 'button.archive[id^="archive-"]',
                buttonUnarchive: 'button.unarchive[id^="unarchive-"]',
                buttonMarkAsRead: 'button.read[id^="read-"]',
                buttonMarkAllAsRead: 'button#mark-all-read',
                buttonLoadMore: 'button#notifications-load-more',
                inputTextSearch: '#notifications-search',
                inputCheckboxIncludeArchived: '#notifications-include-archived',
                notificationBell: 'button#show-notification-modal',
                notificationBellIcon: 'i#notification-bell-icon',
                notificationUnreadLabel: 'span#total-unread-notifications',
                notificationCardBody: 'div.notification-body',
            },
        };

        this.url  = null;
        this.includeArchived = false;
        this.maxResults = 5;
        this.resultsBatch = this.maxResults;

        this.delayTimer = null;

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function () {
            };
        }

        let self = this;
        this.$(function () {
            self.initEventListeners();
        });
    }

    initEventListeners() {
        console.log('DEBUG Notifications - Initializing event listeners...');

        $(document).on('click', this.config.selectors.buttonShow, $.proxy(this.initOnClick, this));
        $(document).on('click', this.config.selectors.buttonLoadMore, $.proxy(this.loadMore, this));
        $(document).on('click', this.config.selectors.buttonMarkAllAsRead, $.proxy(this.markAllRead, this));
        $(document).on('keyup', this.config.selectors.inputTextSearch, $.proxy(this.search, this));
        $(document).on('change', this.config.selectors.inputCheckboxIncludeArchived, $.proxy(this.toggleArchived, this));
        $(document).on('click', this.config.selectors.notificationCardBody + ' a', $.proxy(this.anchorClickToReadAction, this));

        console.log('DEBUG Notifications - Done initializing event listeners');
    }

    initOnClick(event) {
        if ($(event.currentTarget).data('url') === undefined) {
            return;
        }

        this.url = $(event.currentTarget).data('url');
        this.onEventTriggered(event);
    }

    archiveAction(event) {
        let self = this;

        if ($(event.currentTarget).data('url') === undefined) {
            return;
        }

        const button = $(event.currentTarget);

        $.post(
            $(button).data('url'),
            {},
            function (response) {
                self.onEventTriggered(event, $(self.config.selectors.inputTextSearch).val());

                self.refeshNotificationBell();
            }
        );
    }

    unarchiveAction(event) {
        let self = this;

        if ($(event.currentTarget).data('url') === undefined) {
            return;
        }

        const button = $(event.currentTarget);

        $.post(
            $(button).data('url'),
            {},
            function (response) {
                self.onEventTriggered(event, $(self.config.selectors.inputTextSearch).val());
            }
        );
    }

    readAction(event) {
        let self = this;

        if ($(event.currentTarget).data('url') === undefined) {
            return;
        }

        const button = $(event.currentTarget);

        $.post(
            $(button).data('url'),
            {},
            function () {
                $(button).fadeOut("normal", function () {
                    $(button).remove();
                });

                self.refeshNotificationBell();
            }
        );
    }

    anchorClickToReadAction(event) {
        event.preventDefault();

        let self = this;

        let currentTarget = event.currentTarget;

        let markAsReadUrl = $(currentTarget).closest(self.config.selectors.notificationCardBody).data('notification-mark-as-read-url');

        let currentState = $(currentTarget).closest(self.config.selectors.notificationCardBody).data('notification-state');

        if ('unread' !== currentState) {
            window.location.href = $(currentTarget).attr('href');

            return;
        }

        $.post(
            markAsReadUrl,
            {},
            function () {
                window.location.href = $(currentTarget).attr('href');
            }
        );
    }

    onEventTriggered(event, terms = '') {
        $('body').css('cursor', 'wait');
        $('.modal-body').css('pointer-events', 'none');

        $.get(
            this.url,
            {
                terms: terms,
                include_archived: this.includeArchived,
                start: 0,
                maxResults: this.maxResults
            },
            $.proxy(function (response) {
                this.replaceList(response);
            }, this)
        );
    }

    replaceList(response) {
        $(this.config.selectors.container).html(response);

        $(this.config.selectors.buttonMarkAsArchived).one('click', $.proxy(this.archiveAction, this));
        $(this.config.selectors.buttonUnarchive).one('click', $.proxy(this.unarchiveAction, this));
        $(this.config.selectors.buttonMarkAsRead).one('click', $.proxy(this.readAction, this));

        $(this.config.selectors.modal).modal('show');

        $('body').css('cursor', '');
        $('.modal-body').css('pointer-events', '');
    }

    search(event) {
        let self    = this;
        const terms = $(event.currentTarget).val();

        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(function() {
            self.onEventTriggered(event, terms);
        }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
    }

    toggleArchived(event) {
        let self = this;

        self.includeArchived = ($(event.currentTarget).prop('checked'));

        self.onEventTriggered(event, $(this.config.selectors.inputTextSearch).val());
    }

    loadMore(event) {
        let self = this;

        this.maxResults += this.resultsBatch;

        self.onEventTriggered(event, $(this.config.selectors.inputTextSearch).val());
    }

    markAllRead(event) {
        if ($(event.currentTarget).data('url') === undefined) {
            return;
        }

        let self = this;

        const button = $(event.currentTarget);

        $.post(
            $(button).data('url'),
            {},
            function () {
                self.onEventTriggered(event);
            }
        );
    }

    refeshNotificationBell() {
        $.get(
            $(this.config.selectors.notificationBell).data('url-count-unread'),
            {},
            $.proxy(function (response) {
                if (response.count > 0) {
                    $(this.config.selectors.notificationBell).removeClass('btn-light').addClass('btn-red');
                    $(this.config.selectors.notificationUnreadLabel).removeClass('d-none');
                    $(this.config.selectors.notificationUnreadLabel).html(response.count);
                } else {
                    $(this.config.selectors.notificationBell).removeClass('btn-red').addClass('btn-light');
                    $(this.config.selectors.notificationUnreadLabel).addClass('d-none');
                }
            }, this)
        );
    }
}
