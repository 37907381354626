import ParameterTypeSwitcher from "../_parameter/ParameterTypeSwitcher"
import ParameterDeclarationSwitcher from "../_parameter/ParameterDeclarationSwitcher"
import ParameterAbsoluteOnlyDeclarationSwitcher from "../_parameter/ParameterAbsoluteOnlyDeclarationSwitcher"
import Collections from "../_ui/Collections"
import $ from "jquery";

export default class {
    constructor(config) {
        this.$ = $;

        // default config:
        this.config = {
            debug: true,
            selectors: {
                modal: '#time-registration-modal',
                container: '#time-registration',
                form: '#time-registration form[name="time_registration"]',
                buttonShow: '#show-time-registration-form, .edit-timeregistration-button',
                entityDropdowns: 'form[name="time_registration"] select:not([name^="time_registration[parameters]"],[name^="time_registration[project_status]"])',
                definitionField: '#time_registration_definition',
                fileField: '#time_registration_file',
                projectField: '#time_registration_project',
                openAnotherField: 'input[name="open_another"]',
                descriptionField: '#time_registration_description',
            },
            currentFile:'',
            currentProject:''
        };

        this.url  = null;
        this.init = true;

        // if dropdown defined in the key has no value, the dropdowns defined in the value must be disabled
        this.selectElementsDependencyMap = {
            'time_registration_employee': [
                'time_registration_file',
                'time_registration_project',
                'time_registration_project_status',
                'time_registration_definition'
            ],
            'time_registration_file': [
                'time_registration_project',
                'time_registration_project_status',
                'time_registration_definition'
            ],
            'time_registration_project': [
                'time_registration_project_status',
                'time_registration_definition'
            ]
        };

        this.tabSelectElements = [
            'time_registration_employee',
            'time_registration_file',
            'time_registration_project',
            'time_registration_project_status',
            'time_registration_definition',
        ];

        this.tabDateElements = [
            'time_registration_startDateTime_date',
            'time_registration_startDateTime_time',
            'time_registration_endDateTime_time',
        ];

        this.tabTextElements = [
            'time_registration_description',
            'time_registration_internal_note'
        ];

        this.tabElements = this.tabSelectElements
            .concat(this.tabDateElements)
            .concat(this.tabTextElements);

        this.$.extend(true, this.config, config);

        if (parseInt(this.config.debug) !== 1) {
            console.log = function () {
            };
        }

        var self = this;
        this.$(function () {
            self.initEventListeners();
        });
    }

    initEventListeners() {
        console.log('DEBUG TimeRegistration - Initializing event listeners...');

        $(document).on('click', this.config.selectors.buttonShow, $.proxy(this.initOnClick, this));
        $(this.config.selectors.modal).on('hidden.bs.modal', $.proxy(this.reset, this));

        console.log('DEBUG TimeRegistration - Done initializing event listeners');
    }

    reset() {
        $(this.config.selectors.container).empty();
        $('body').css('cursor', '');
        $('.modal-body').css('pointer-events', '');
        this.init = true;
    }

    initOnClick(event) {
        if ($(event.currentTarget).data('url') === undefined) {
            return;
        }

        this.url = $(event.currentTarget).data('url');
        this.onEventTriggered(event);
    }

    initParameters() {
        var typeSwitcher = new ParameterTypeSwitcher({
            debug: this.config.debug,
            selectors: {
                fieldToSwitchContainer: '.parameter_value_widget',
                fieldToSwitch: '[name*="[value_%type%]"]',
            },
        });

        var declarationSwitcher = new ParameterDeclarationSwitcher({
            debug: this.config.debug,
            selectors: {
                valueElements: '[name*="[value]"]',
                valueElementsContainer: '.parameter_value_widget',
            },
            callbacks: {
                afterSwitch: $.proxy(function ($row) {
                    let $declaration = $row.find('[name*="[declaration]"]');
                    let $type = $row.find('[name*="[type]"]');
                    if (!$declaration.is(':checked')) {
                        typeSwitcher.switchVisibleField($type);
                    }
                }, this),
            }
        });

        var absoluteOnlyDeclarationSwitcher = new ParameterAbsoluteOnlyDeclarationSwitcher({
            debug: this.config.debug,
            selectors: {
                valueElements: '[name*="[value]"]',
                valueElementsContainer: '.parameter_value_widget',
            },
        });

        var lists = new Collections({
            debug: this.config.debug,
            collections: {
                '.parameters': {
                    prefix: 'parameter',
                    allow_duplicate: false,
                    elements_selector: 'div.row',
                    allow_up: false,
                    allow_down: false,
                    allow_remove: false,
                    allow_add: false,
                    add: '',
                    remove: '',
                    add_at_the_end: true,
                    after_init: $.proxy(function(collection, element) {
                        this.refreshSelect2Entity();
                    }, this)
                },
                '.employees': {
                    prefix: 'employee',
                    allow_duplicate: false,
                    elements_selector: 'div.form-group',
                    allow_up: false,
                    allow_down: false,
                    add_at_the_end: true,
                    add: '<a href="#" class="text-success"><i class="fa fa-plus-square collection-action" aria-hidden="true"></i></a>',
                    allow_remove: true,
                    after_add: $.proxy(function(collection, element) {
                        this.refreshSelect2Entity();
                    }, this)
                }
            }
        });
    }

    onEventTriggered(event) {
        // no api call on removing a value
        if ($(event.target).is($(this.config.selectors.entityDropdowns))) {
            if ('' === event.target.value) {
                return false;
            }
        }

        $('body').css('cursor', 'wait');
        $('.modal-body').css('pointer-events', 'none');

        this.disableDependingDropdowns(event.currentTarget.id);

        let form = $(this.config.selectors.form).serialize();

        // if project wasnt chosen yet, project_status will be empty. When the form is serialized, this value will not be passed in the request.
        // project_status missing from the request means the user did not have the rights to change the project status, (thus, the project status input field is not in the form)
        // to avoid making wrong conclusions in the backend based on the availability of project status, add project_status to the request if project_status is in the form
        const projectStatusUri = encodeURI('time_registration[project_status]=');
        if ($('#time_registration_project_status').length && ! form.includes(projectStatusUri)) {
            form += '&' + projectStatusUri;
        }

        form += '&currentProject=' + this.config.currentProject;
        form += '&currentFile=' + this.config.currentFile;

        $.get(
            this.url,
            form,
            $.proxy(function (response) {
                this.replaceForm(response);

                if (this.init) {
                    if (! $('#time_registration_project_status').length
                    && this.tabSelectElements.includes('time_registration_project_status')) {
                        this.tabSelectElements = this.tabSelectElements.filter(
                            element => element !== 'time_registration_project_status'
                        );

                        for (let dependency in this.selectElementsDependencyMap) {
                            if (! this.selectElementsDependencyMap.hasOwnProperty(dependency)) {
                                continue;
                            }

                            this.selectElementsDependencyMap[dependency] = this.selectElementsDependencyMap[dependency].filter(
                                element => element !== 'time_registration_project_status'
                            );
                        }

                        this.tabElements = this.tabSelectElements
                            .concat(this.tabDateElements)
                            .concat(this.tabTextElements);
                    }

                    this.initTabs();
                }

                this.init = false;
            }, this)
        );
    }

    disableDependingDropdowns(target) {
        const dependents = this.selectElementsDependencyMap[target];

        if (! dependents) {
            return;
        }

        dependents.forEach(function (dependent) {
            const $dependent = $('#' + dependent);

            $dependent.val('');
            $dependent.trigger('change.select2');
            $dependent.attr('readonly', 'readonly');
        });
    }

    replaceForm(response) {
        $(this.config.selectors.container).html(response);
        this.afterRefresh();
    }

    afterRefresh() {
        $('.datepicker').each(function (i, element) {
            $(element).datepicker({
                format: 'dd/mm/yyyy',
                language: 'nl-BE',
                todayHighlight: true,
                startDate: $(element).data('lower-limit-date') ? $(this).data('lower-limit-date') : false,
                endDate: $(element).data('upper-limit-date') ? $(this).data('upper-limit-date') : false
            });
        });

        Inputmask().mask($('input[data-inputmask]'));

        $('.datepicker').on('focus', function (e) {
            var that = $(this);
            setTimeout(function(){
                that.select();
            },10);
        });

        $(this.config.selectors.entityDropdowns).on('change', $.proxy(this.onEventTriggered, this));
        $(this.config.selectors.form).on('submit', $.proxy(this.onSubmit, this));
        $(this.config.selectors.modal).modal({backdrop: 'static'});
        $(this.config.selectors.modal).modal('show');

        this.initParameters();
        this.refreshSelect2Entity();

        $('body').css('cursor', '');
        $('.modal-body').css('pointer-events', '');

        window.autosizeTextareas();
    }

    refreshSelect2Entity() {
        this.guaranteeInputOrder();

        $('.select2entity').each(function (i, e) {
            if ($(e).is('[readonly]')) {
                $(e).attr('tabindex', -1);
            }
        });

        $(this.config.selectors.modal + ' .select2entity[data-autostart="true"]:not([multiple])').select2entity({
            dropdownParent: $(this.config.selectors.container)
        });

        $(this.config.selectors.modal + ' .select2entity[data-autostart="true"][multiple="multiple"]').select2_sortable({
            dropdownParent: $(this.config.selectors.container)
        });

        $(this.config.selectors.fileField + ', ' + this.config.selectors.projectField).on('select2:unselect', function (e) {
            $(this).closest('.form-group').find('.entity-link .fa-link').css('visibility', 'hidden');
        });

        $(this.config.selectors.modal + ' .select2entity[data-autostart="true"]:not([multiple]), '
            + this.config.selectors.modal + ' .select2entity[data-autostart="true"][multiple="multiple"]')
            .on('select2:unselect', $.proxy(function (e) {
            this.disableDependingDropdowns(e.currentTarget.id);
        }, this));
    }

    initTabs() {
        console.log('DEBUG TimeRegistration - Initializing tab listeners...');

        // makes sure the first tab, isn't the button to close the modal
        $('.close').attr('tabindex', -1);

        var tabElements         = this.tabElements;
        var last_active_element = 'time_registration_employee';

        var tabInputStringElements = '#' + this.tabDateElements.concat(this.tabTextElements).join(', #');
        var tabDateStringElements  = '#' + this.tabDateElements.join(', #');

        $(document).on('keydown', function(e) {
            let last = localStorage.getItem('tr_last_selected');

            if (e.keyCode !== 9) {
                return;
            }

            // Implementation based on the localStorage is to make sure tabbing still works after refreshing the form
            if (last) {
                e.preventDefault();

                tabToNextElement(last);

                localStorage.removeItem('tr_last_selected');

                return;
            }

            // Implementation based on last_active_element is to make sure tabbing works properly in general (apart of refreshing the form or not)
            // For example, when clicking out of an element, when tabbing, you will still go to the next select/input
            // When an element is focused (on an input field), the default tabbing is fine, so dont do anything
            if (! $(tabInputStringElements).is(':focus')
                && null !== last_active_element && undefined !== last_active_element) {
                e.preventDefault();

                tabToNextElement(last_active_element);
            }
        });

        $(document).on('focus', tabDateStringElements, function (e) {
            var that = $(this);
            setTimeout(function(){
                that.select();
            },10);

            //this.setSelectionRange(0, 1);
            //this.setCursorPosition(0);
        });

        // save last active element when leaving an input field
        $(document).on('blur', tabInputStringElements, function (e) {
            last_active_element = e.target.id;
        });

        // save last active element when leaving a select field
        $(document).on('#time_registration select2:close', function(e) {
            last_active_element = e.target.id;
        });

        // when start date is set, set end date as the same date
        $(document).on('change', '#time_registration_startDateTime_date', function (e) {
            let $endDate = $('#time_registration_endDateTime_date');

            // Update end date value in the text field
            $endDate.val($(this).val());

            // This line replaces DOM-value because apparently it does not get changed by .val()
            $endDate.attr('value', $(this).val());

            // We need to update the datepicker, otherwise the date of today is still selected
            $endDate.datepicker('update');
        });

        // end date is skipped (because start & end date will (almost) always be the same).
        // But if it was chosen set index-1 of endDateTime_time as the "last selected" item
        $(document).on('focus', '#time_registration_endDateTime_date', function (e) {
            localStorage.setItem('tr_last_selected', 'time_registration_startDateTime_time');
        });

        function tabToNextElement(element) {
            let index = tabElements.indexOf(element);

            if (index !== -1) {
                const $nextElement = $('#' + tabElements[index + 1]);

                if ($nextElement.attr('readonly') === 'readonly') {
                    tabToNextElement(tabElements[index + 1]);

                    return;
                }

                $nextElement.focus();
                last_active_element = null; // leaving the element will set the last active element, so reset it here
            }
        }

        console.log('DEBUG TimeRegistration - Done initializing tab listeners...');
    }

    guaranteeInputOrder() {
        const elements = this.selectElementsDependencyMap;

        for (const dependency in elements) {
            if (! elements.hasOwnProperty(dependency)) {
                continue;
            }

            // if the dropdown has no value
            if (! $('#' + dependency).val()) {
                // then disable all elements which are dependent on this dropdown
                elements[dependency].forEach(function (value) {
                    ($('#' + value).attr('readonly', 'readonly'));
                });

                break;
            }
        }
    }

    onSubmit(event) {
        event.preventDefault();

        $.post(
            this.url,
            $(this.config.selectors.form).serialize(),
            $.proxy(function (response) {
                if (response.indexOf('form-error-message') !== -1) {
                    this.replaceForm(response);
                } else if ($(this.config.selectors.container + ' ' + this.config.selectors.openAnotherField).is(":checked")) {
                    this.replaceForm(response);
                } else {
                    $(this.config.selectors.modal).modal('hide');

                    localStorage.removeItem('tr_last_selected');

                    if (this.url.indexOf('/change') !== -1) {
                        location.reload();
                    }
                }

                this.init = true;
            }, this)
        );
    }
}
